.border-block{
    border: solid 1px #aaa;
}
.block-item-expert .loader-expert-image{
    width: 100px;
    height: 100px;
    margin: auto;
    border-radius: 50%;
    background-color: #eee !important;
    margin-top: 5px;
}
.block-expert-voir {
    height: 40px;
    margin: -5px;
    margin-top : 5px;
    border-top: solid 1px #eee;
    position: relative;
}
.block-expert-voir .btn-voir-expert{
    position: absolute;
    top: 0px;
    right: 10px;
    border: 1px solid #b8c7c8;
    padding: 0 15px;
    color: #b8c7c8;
}
.block-fillter-expert h4.h4{
    border-bottom: 1px solid #eaeaea;
    padding-top: 18px;
    font-weight: 700;
    font-size: 14px;
}
input[type=checkbox],input[type=radio] {
    display: none;
}

input[type=checkbox] + label,input[type=radio] + label{
    display: inline-block;
    cursor: pointer;
}

input[type=checkbox]:checked + label,input[type=radio]:checked + label{
background-position: left -32px;
}
input[type=checkbox] + label:before,input[type=radio] + label:before{
    border: solid 1px;
    border-radius: 50px;
}
input[type=checkbox] + label:hover:before,input[type=radio] + label:hover:before{
    border-color: #e11a18;
}
input[type=checkbox]:checked + label:before ,input[type=radio]:checked + label:before {
    border-width: 7px;
    border-color: #e11a18;
    border-radius: 50px;
}
input[type=checkbox]:checked + label:after ,input[type=radio]:checked + label:after{
    left: 3px !important;
}

.block-item-expert .expert-image {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.block-item-expert .expert-image .profile-pctiure {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}
.block-item-expert .survey-name{
    display: block;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
}

.block-item-expert .survey-country{
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    color: #A1A1A4;
}
.block-item-expert .survey-completes{
    line-height: 21px;
    font-size: 13px;
    vertical-align: middle;
}

.size-titre-b2b {
    font-size: 18px !important;
    font-weight: 600 !important;
}
.grie-b2b {
    color: #7c7c7c !important;
}
a.user-aussi-item.item {
    height: 250px;
    display: block;
    border: solid 1px #aaa;
    padding: 10px;
}
.user-aussi-item img.user-aussi-image {
    width: 100px;
    height: 100px;
    margin: auto;
    border-radius: 50%;
    object-fit: cover;
}
.user-aussi-item h6 {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
}

.user-aussi-item p.grid-only {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    color: #a1a1a4;
}
.user-aussi-item h6 {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
}
.user-aussi-item p.grid-only {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    color: #a1a1a4;
}
.user-aussi-item p.red-only {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    color: red;
}
.user-aussi-item figure{
    display: flex;
    width: 100%;
    justify-content: center;
}
.page-expert .profile-photo-expert {
    position: relative;
    width: 350px;
    height: 350px;
}
.page-expert .profile-photo-expert .profile-photo {
    width: 350px;
    height: 350px;
    border-radius: 100%;
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.page-expert .profile-photo-expert .profile-bg {
    position: absolute;
    z-index: 1;
    width: 350px;
    height: 350px;
    border-radius: 100%;
    background-color: #e11a18;
    top: 2px;
    left: 2px;
}

.show-mobile{
    display: none;
}
.competence-freelance span {
    display: none;
}
.competence-freelance .competence {
    padding: 10px;
    background-color: red;
    color: #fff;
    position: relative;
    border-radius: 50px;
    display: block;
    width: fit-content;
    margin: 10px;
}
.competence-freelance .competence::after {
    content: " ";
    width: 96%;
    height: 90%;
    position: absolute;
    top: 5%;
    left: 2%;
    border-radius: 50px;
    border: solid 1px #fff;
}
.competence-freelance {
    display: flex;
    flex-wrap: wrap;
}
.domaine-emp {
    padding: 10px;
    background-color: #fff;
    color: red;
    position: relative;
    border-radius: 50px;
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    margin: 10px;
    border: solid 1px red;
}
.resume-item.border-line-h .date{
    border-color: #e11a18 !important;
    color: #000 !important;
}
.block-search-btn .search-btn{
    border-radius: 0px;
}
@media (max-width: 700px){
    .block-search-btn .search-btn{
        border-radius: 25px 25px 0px 0px;
    }
    .block-search-btn {
        flex-wrap: wrap;
    }
    .show-mobile{
        display: block;
    }
    .block-fillter-expert.block-fillter-mobile{
        position: fixed;
        top: 70px;
        right: -80%;
        z-index: 999999;
        background-color: #fff;
        width: 70%;
        height: 100%;
        overflow: auto;
        animation:fadeIn 5s
    }
}
