.page-expert {
    margin-top: 60px;
}

.span_accueil {background: url('./icons/accueil.png');}
/* .span_accueil:hover {background: url('./icons/accueil-blanc.png');} */
.active .span_accueil {background: url('./icons/accueil-blanc.png');}

.span_presentation{background: url('./icons/presentation.png');}
/* .span_presentation:hover{background: url('./icons/presentation-blanc.png');} */
.active .span_presentation{background: url('./icons/presentation-blanc.png');}

.span_videos{background: url('./icons/video-de-presentation.png');}
/* .span_videos:hover{background: url('./icons/video-de-presentation-blanc.png');} */
.active .span_videos{background: url('./icons/video-de-presentation-blanc.png');}

.span_formation{background: url('./icons/formation.png');}
/* .span_formation:hover{background: url('./icons/formation-blanc.png');} */
.active .span_formation{background: url('./icons/formation-blanc.png');}

.span_experience{background: url('./icons/experience.png');}
/* .span_experience:hover{background: url('./icons/experience-blanc.png');} */
.active .span_experience{background: url('./icons/experience-blanc.png');}

.span_portfolio{background: url('./icons/portfolio.png');}
/* .span_portfolio:hover{background: url('./icons/portfolio-blanc.png');} */
.active .span_portfolio{background: url('./icons/portfolio-blanc.png');}

.span_contact{background: url('./icons/contact.png');}
/* .span_contact:hover{background: url('./icons/contact-blanc.png');} */
.active .span_contact{background: url('./icons/contact-blanc.png');}

.span_accueil,.span_videos,.span_formation,.span_experience,.span_portfolio,.span_contact,
.active .span_accueil,.active .span_videos,.active .span_formation,.active .span_experience,.active .span_portfolio,.active .span_contact{
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px 30px;
}
.top-menu li.active , .top-menu li.active a{
    background-color: #e11a18 !important;
}
.top-menu li.active a .link{
    color: #fff !important;
}
#block-info-expert .info-list ul li{
    color: #aaa;
}
#block-info-expert .info-list ul li strong {
    background: transparent;
    color: #323232;
    font-weight: 600;
    display: block;
    width: 100%;
}
.info-list ul li{
    text-align: left;
}
.info-list ul li strong{
    background: #e0e0e0;
    color:#e11a18;
}
.info-list ul li {
    padding: 5px 0;
}
.info-list {
    margin-bottom: 20px;
}
.content.contacts .info-list ul li{
    width: 100% !important;
}.info-list ul li,.date{
    text-transform: capitalize !important;
}
#about-card .info-list ul{
    width: 100%;
    /*margin: auto;*/
}
#about-card .info-list ul li{
    width: fit-content;
    /*margin: auto;*/
}
.card-wrap .title-expert {
    margin-bottom: 0 !important;
    min-height: 0 !important;
}
.content .title-expert {
    color: #323232;
    text-transform: uppercase;
    font-size: 20px;
    color: #323232;
    line-height: 21px;
    min-height: 51px;
    font-weight: 700;
    z-index: 10;
    margin: 0 0 15px 0;
    padding: 0 0 5px 0;
    position: relative;
}
.info-list ul li strong {
    float: left;
    margin-right: 8px;
    padding: 1px 8px;
    background: #e11a18;
    font-size: 14px;
    line-height: 20px;
    color: #fefefe;
    font-weight: 400;
    text-transform: none;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -khtml-border-radius: 2px;
}
.info-list ul li strong {
    display: inline-block;
    font-size: 13px;
    color: #171717;
    font-weight: 500;
    text-transform: uppercase;
}
.ion-videocamera:before {
    content: "\f256";
}
.modal-login-hadeer.modale-youtube>div>div{
    width: 100%!important;
    height: 60vh!important;
}
.loder-span{
    width: 100px;
    height: 20px;
    background-color: #eee;
    margin-left: 150px;
    display: block;
}
.loder-p{
    width: 100%;
    height: 15px;
    background-color: #eee;
    display: block;
    margin-bottom: 5px;

}
.contentent-btn-offre {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 10px;
}
.btn-offre-hr {
    margin: 10px;
    border-right: solid 1px #e11a18;
}
.info-list ul li {
    font-size: 14px;
}
.info-list ul li strong {
    background: #e0e0e0;
    color: #e11a18;
    float: left;
    margin-right: 8px;
    padding: 1px 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-transform: none;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -khtml-border-radius: 2px;
}
.align-left {
    text-align: left !important;
}
.button.btn-b2b {
    height: 40px;
    line-height: 30px;
    float: right;
}
.button.btn-b2b .text {
    padding: 0;
    font-size: 12px;
}
.div-content-typed{
    min-height: 40px;
}
.modal-devi-login>div>div{
    width: 60%;
}
.list-single-main-item .product-list .alice-carousel__next-btn{
    right: 0px !important;
    top: 30%;
}
.list-single-main-item .product-list .alice-carousel__prev-btn{
    left: 0px !important;
    top: 30%;
}


.modal-login-hadeer.proposer>div>div{
    width: 70% !important;
}
.modal-login-hadeer.proposer .flex-space-between{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media only screen and (max-width: 760px) {
    .page-expert .div-login-expert {
        min-width: auto;
    }
    .modal-login-hadeer>div>div{
        width: 100% !important;
        padding: 5px !important;
    }

}

.contact-button__wrapper .btn-b2b.emploi-button,.contact-button__wrapper .btn-b2b.freelance-button {
    height: 40px;
    padding: 5px 20px !important;
    text-align: center;
    line-height: 13px;
}
.page-expert #block-info-expert .info-list ul li{
    display: flex;
    align-items: center;
    width: 100%;
}
i.icon-profil-expert {
    width: 50px;
    height: 50px;
    display: block;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: center;
}
.page-expert #block-info-expert .info-list ul li strong{
    float: unset;
    margin-right: 0px;
    padding: 1px 1px;
}
.page-expert .continer-profile-photo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}