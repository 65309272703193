.tab button{
    width: 33.3333%;
}

.page-abonnement-b2b .header-abonnement{
    font-size: 20px;
    width: 100%;
    height: 100px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    text-align: center;
    margin: 10px 0px;
   
}
.page-abonnement-b2b .header-abonnement p{
    color: #000;
    margin: 0px !important;
}
.page-abonnement-b2b .header-abonnement span{
    color: #e72929;
    display: block;
    width: 100%;
}
.page-abonnement-b2b .header-abonnement h2{
    color: #8c8c8c;
    font-size:30px;
    line-height: 30px;
}
.page-abonnement-b2b .bg-abonnement-1,.page-abonnement-b2b .body-abonnement.abonnement-1 .bg-1{
    background-color: #dadbdc;
}
.page-abonnement-b2b .bg-abonnement-2,.page-abonnement-b2b .body-abonnement.abonnement-2 .bg-1{
    background-color: #fad4d4;
}
.page-abonnement-b2b .bg-abonnement-3,.page-abonnement-b2b .body-abonnement.abonnement-3 .bg-1{
    background-color: #b7b8ba;
}
.page-abonnement-b2b .body-abonnement.abonnement-0 .bg-1{
    background-color: #dadbdc ;
}
.page-abonnement-b2b .body-abonnement ul li{
    height: 80px;
    overflow: auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1px;
}
.page-abonnement-b2b .body-abonnement ul li img{
    max-height: 30px;
}
.page-abonnement-b2b .body-abonnement ul li p{
    margin: 0px !important;
    font-size: 14px;
    line-height: 16px;
    color: #2d2d2d;
    width: 100%;
}
.page-abonnement-b2b .body-abonnement.abonnement-1 li p,.page-abonnement-b2b .body-abonnement.abonnement-2 li p,.page-abonnement-b2b .body-abonnement.abonnement-3 li p{
    font-weight: 700;
    text-align: center;
    display: block;
    width: 100%;
    justify-content: center;
}
.page-abonnement-b2b .body-abonnement.abonnement-1 li p span,.page-abonnement-b2b .body-abonnement.abonnement-2 li p span,.page-abonnement-b2b .body-abonnement.abonnement-3 li p span{
    text-align: center;
    display: block;
    width: 100%;
}
.page-abonnement-b2b .btn-acheter-pack-b2b{
    width: 100%;
    height: 60px;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e72929;
    color: #fff;
    border-radius: 10px;
    text-transform: uppercase;
}
.page-abonnement-b2b span.size-prix-pack {
    font-size: 20px;
}
.page-abonnement-b2b .abonnement-desktop{
    display: flex;
} 
.page-abonnement-b2b .abonnement-mobile{
    display: none;
}
@media only screen and (max-width: 900px) {
    .page-abonnement-b2b .abonnement-desktop{
        display: none;
    } 
    .page-abonnement-b2b .abonnement-mobile{
        display: block;
    }
}
.btn-acheter-pack-b2b.disabled {
    cursor: no-drop;
    background-color: #aaa;
}