.alice-carousel__prev-btn,.alice-carousel__next-btn{
    position: absolute;
    top:calc(45% - 30px);
}
.alice-carousel__prev-btn{
    left: 5px !important;
}
.alice-carousel__next-btn{
    right: 5px !important;
}
.alice-carousel__prev-btn{
    text-align: left !important;
}
.alice-carousel__next-btn{
    text-align: right !important;
}

.alice-carousel__prev-btn p,.alice-carousel__next-btn p{
    background-color: black;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    font-size: 18px;
    font-weight: 900;
    margin-top: 0px !important;
}
.alice-carousel__dots{
    display: none !important;
}
.main-slider .alice-carousel__prev-btn p,.main-slider .alice-carousel__next-btn p{
    width: 30px;
    height: 100px;
    background-color: #e11a18;
    padding-top: 10px;
}
.main-slider .alice-carousel__prev-btn{
    left: 0px !important;
    padding-left: 0px;
}
.main-slider .alice-carousel__next-btn{
    right: 0px !important;
    padding-right: 0px;
}
.main-slider .alice-carousel__prev-btn,.main-slider .alice-carousel__next-btn{
    position: absolute;
    top:150px;
}
.item-slide-home{
    position: relative;
}
.text-slide-home{
    position: absolute;
    top: 50px;
    left: 15%;
    padding-top: 100px;
    padding-left: 50px;
}
.text-slide-home h2{
    font-family: MoolBoran, sans-serif;
    transition: none;
    text-align: left;
    line-height: 60px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 500;
    font-size: 55px;
    color: #000;
}
.text-slide-home h2 span{
    transition: none;
    text-align: left;
    line-height: 60px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 500;
    font-size: 55px;
    color: #e11a18;
}
.text-slide-home p{
    transition: none;
    text-align: left;
    line-height: 30px;
    border-width: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 500;
    font-size: 20px;
    max-width: 600px;
    color: #000;
}
.dlab-post-text p {
    height: 100px;
    overflow: hidden;
    margin-top: 0!important;
}
.section-actualite .alice-carousel__prev-btn ,.section-actualite .alice-carousel__next-btn{
    display: none !important;

}
.list-group-ul{
    max-height: 250px;
    overflow: auto;
}
.list-group-item{
    font-size: 13px;
    padding: 6px;
}
.span-insideli{
    background: #e11a18;
    color: #fff!important;
    border-radius: 50%;
    padding: 0 4px;
    margin-right: 10px;
    font-size: 10px;
}
.icon-login-user{
    font-size: 25px;
    color: red;
    margin-top: 10px;
}
.categories-wrapper .categories-name{
    margin-left: 25px;
}
.categories-wrapper .number-categories{
    position: absolute;
    top: 12px;
    left: 4px;
}
.loader-image{
    height: 150px;
    width: 100%;
    background-color: #eee;
}
.loader-title {
    height: 40px;
    width: 100%;
    background-color: #eee;
    margin-top: 5px;
}
.actualiter-loader-img {
    height: 200px;
    background-color: #eee;
}
.actualiter-loader-title {
    height: 50px;
    background-color: #eee;
}
.actualiter-loader-description {
    height: 150px;
    background-color: #eee;
}
.actualiter-loader-date{
    background-color:#eee
}
.actualiter-loader-categorie{
    width: 80px;
    display: block;
}
.w-slide-b2b{
    height:"400px";
    position: "relative";
}
.region-loader .categories-name{
    background-color: #eee;
    width: 70%;
    height: 10px;
}

.region-loader .number-categories{
    width: 20px;
    height: 20px;
}
.loader-secteurs-cont{
    width: 20px;
    height: 20px;
    display: block;
    float: left;
}
.loader-secteurs-name{
    background-color: #eee;
    width: calc(100% - 30px);
    height: 20px;
    display: block;
    float: right;
}
.loader-recommande-image {
    height: 60px;
    background-color: #eee;
}
.loader-recommande-name {
    background-color: #eee;
    height: 40px !important;
}
.home-search-input input {
    width: calc(100% - 40px);
    background-color: #fff;
    margin: 0 20px;
    padding: 10px;
    height: 50px;
    border: 0;
}

.home-search-input {
    margin-bottom: 60px;
}
.item-actualite .post-title{
    height: 75px;
    font-family: 'Open Sans',sans-serif !important;
    overflow: hidden;
}

.item-actualite .dlab-post-text{
    height: 120px;
    overflow: hidden;
}

@media (max-width: 700px) {
    .w-slide-b2b{
        height: 150px !important;
    }
    .main-slider .alice-carousel__next-btn, .main-slider .alice-carousel__prev-btn {
       display: none;
    }
    .text-slide-home {
        position: absolute;
        top: 0px;
        left: 0px;
        padding-top: 30px;
        padding-left: 60px;
        zoom: 60%;
    }
    .col-sm-6{
        width: 50%;
    }
    .section-full.text-white.bg-img-fix.content-inner.overlay-black-middle{
        zoom: 80%;
    }
}
.alice-carousel__prev-btn, .alice-carousel__next-btn{
    width: 40px !important;
}
.panel-collapse-collapse {
    display: none;
    position: absolute;
    position: fixed;
    top: 0px;
    width: 100%;
    left: 0px;
    background-color: #00000055;
    padding: 5px;
    display: none;
    z-index: 24;
    height: 100%;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}

.panel-collapse-collapse.show {
    display: flex;
   
}
.panel-collapse-collapse .w-100{
    width: 100%!important;
}
.panel-collapse-collapse.modal-login-hadeer>div{
    backdrop-filter: unset!important;
    background: unset!important;
}
#fa-eye-home-b2b{
    font-size:15px !important;
    color:#aaa !important;
}

.item-slide-home img{
    width: 100%;
    height: 100%;
}

.link-img{
   width: 90% !important
}