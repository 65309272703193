.is_user_logged_in {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: #fff;
    width: 100%;
    padding: 20px;
    text-align: center;
    z-index: 99999999999999999;
    box-shadow: 0px 0px 40px #00000055;
}
.myb2b-btn {
    position: relative;
    padding-left: 80px !important;
    padding-right: 40px !important;
}
.is_user_logged_in .myb2b-ou {
    padding: 10px 50px;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    position: relative;
}
.myb2b-ou::after, .myb2b-ou::before {
    content: '';
    position: absolute;
    border: solid 1px #eee;
    width: 30px;
    height: 2px;
    top: 20px;
    right: 10px;
}
.myb2b-ou::before {
    top: 20px;
    left: 10px;
    right: unset;
}
.myb2b-btn .icone-btn-connecter {
    top: -5px;
}
.icone-btn-connecter {
    /*background-image: url(/upload/image/b2b.png);*/
    background-size: 40px 30px;
    background-position: center;
    width: 50px;
    height: 40px;
    display: block;
    position: absolute;
    left: 0px;
    background-repeat: no-repeat;
    margin-left: 10px;
}
.sidebar .widget {
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    background: #ffffff;
    border: 1px solid #b8c7c8;
    border-radius: 3px;
    padding:20px;
}
.central-meta {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #b8c7c8;
    border-radius: 3px;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    padding: 25px;
}
.friend-info > figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    width: 8%;
}
.friend-name {
    display: inline-block;
    padding-left: 10px;
    vertical-align: middle;
    width: 90%;
}
.post-meta {
    float: left;
    margin-top: 15px;
    width: 100%;
}
.friend-name > ins {
    float: left;
    font-weight: 700;
    width: 100%;
    font-size: 18px;
    text-decoration: none;
    color: #E11A18;
}
.friend-name > ins a{
    color: #E11A18;
}
.label-info {
    background: #fff;
    border: 1px solid #e11a18;
    padding: 0 5px;
    border-radius: 3px;
}
.friend-name > span {
    color: #999;
    float: left;
    font-size: 12px;
    text-transform: capitalize;
    width: 100%;
}
.we-video-info {
    padding: 15px 0 10px;
    width: 100%;
    position: relative;
    z-index: 0;
}
.we-video-info > ul {
    width: 100%;
    line-height: 27px;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;

}
.we-video-info > ul li {
    display: inline-block;
    font-size: 13px;
    margin-right: 40px;
}
.modal-login-myb2b>div>div {
    width: 80% !important;
    padding: 30px !important;
    background-color: transparent !important;
    max-width: 950px;
}
.block-text-myb2b {
    background-image: url(./img-myb2b.jpg);
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    border-radius: 5px 0px 0px 5px;
}
.block-text-myb2b p {
    color: #000;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
}
.block-text-myb2b p span {
    background-color: red;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    padding: 0px 10px;
}

.block-login-myb2b .connxtion_body{
    border-radius: 0px 5px 5px 0px;
}
.overflow-text-hidden{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.icon-follow {
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 25px;
    background-image: url(./follow.png);
    border: solid 1px red;
    border-radius: 50px;
    color: red;
    padding: 0px 5px 0px 25px;
    display: block;
    width: fit-content;
}

.your-page {
    display: inline-block;
    padding-bottom: 10px;
    width: 100%;
}
.your-page > figure {
    display: inline-block;
    margin-bottom: 0;
    max-width: 55px;
    vertical-align: middle;
    width: 55px;
}
.your-page .page-meta {
    display: inline-block;
    padding-left: 10px;
    vertical-align: middle;
    width: 69.8%;
}
.your-page .page-meta > a {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    width: auto;
    color: red;
}

.we-comet > li {
    display: inline-block;
    margin-bottom: 20px;
    width: 100%;
}

.comet-avatar {
    display: table-cell;
    max-width: 7.6%;
    vertical-align: top;
    width: 7.6%;
}
.comet-avatar img{
    width: 40px;
    height: 40px;
    border-radius: 50px;
    object-fit: cover;
    box-shadow: 0px 0px 10px #aaa;
    margin-top: 10px;
    margin-left: 5px;
}

.we-comment {
    border: 1px solid #b8c7c8;
    display: table-cell;
    margin-left: 20px;
    padding: 10px;
    vertical-align: top;
    position: relative;
    width: 65%;
}
.we-comment::before, .peoples-mesg-box > ul > li p:before {
    border-bottom: 10px solid transparent;
    border-right: 11px solid #b8c7c8;
    border-top: 10px solid transparent;
    left: -11px;
    top: 20px;
    position: absolute;
    content: '';
}
.we-comment::after, .peoples-mesg-box > ul > li p:after {
    border-bottom: 8px solid transparent;
    border-right: 9px solid #fff;
    border-top: 8px solid transparent;
    left: -9px;
    top: 22px;
    position: absolute;
    content: '';
}
.coment-head {
    display: inline-block;
    width: 100%;
}
.coment-head > h5 {
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 5px;
    text-transform: capitalize;
    display: inline-block;
    margin-bottom: 0;
    color: #2a2a2a;
}
.coment-head > span {
    color: #999;
    font-size: 12px;
    padding-left: 10px;
    display: inline-block;
}
.we-comment > p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0;
    max-width: 100%;
    margin-top: 5px !important;
}
.we-video-info > ul li span ins {
    font-size: 11px;
    left: 20px;
    position: absolute;
    text-decoration: none;
    top: -10px;
}
.we-video-info > ul li span {
    font-size: 16px;
    font-weight: 400;
    margin-right: 3px;
    position: relative;
}
.we-video-info > ul li span.like {
    color: #e11a18;
    cursor: pointer;
    transition: all 0.2s linear 0s;
}
.statictop .sidebar{
    position: static;
    width: 300px;
}
.fixsedtop .sidebar{
    position: fixed;
    width: 300px;
    top: 140px;
    height: calc(100vh - 140px);
    overflow-x: auto;
}
.block-mobile {
    display: none;
}
.fixsedtop .widget .followers{
    overflow: hidden !important;
}
@media only screen and (max-width: 700px) {
    .block-mobile {
        display: flex;
        justify-content: space-between;
        margin-top: -5px;
        z-index: 99999;
    }
    .fixsedtop .sidebar{
        position: unset;
        top: unset;
        height: auto;
        overflow-x: unset;
    }
    #slidemyb2bleft,#slidemyb2bright {
        position: fixed;
        top: 70px;
        z-index: 9999;
        background-color: #fff;
        height: calc(100vh - 70px);
        display: grid;
        justify-content: center;
        padding-top: 10px !important;
        overflow-x: auto;
    }
    #slidemyb2bleft {
        left: -1000px;
    }
    #slidemyb2bright {
        right: -1000px;
    }
    .block-mobile.fixsedtopmobile{
        position: fixed;
        width: 100%;
        top: 72px;
        z-index: 999999999;
    }
    .block-mobile button.btn {
        border: solid 1px red;
        color: red;
        border-radius: 50px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
        cursor: pointer;
    }
    .fixsedtop .widget .followers{
        overflow: auto !important;
    }
}

.deliteComment {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 30px;
    height: 30px;
    border: solid 1px red;
    border-radius: 0px 0px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #e11a18;
    line-height: 0px;
    letter-spacing: 0px;
    border-top: unset;
    border-right: unset;
    cursor: pointer;
}
.deliteComment:hover {
    background-color: #e11a18;
    color: #fff;
}
.block-login-myb2b{
    padding-left: 0px;
}
@media only screen and (max-width: 760px){
    .block-text-myb2b{
        display: none;
    }
    .modal-login-hadeer>div>div {
        width: 100% !important;
        padding: 5px !important;
    }
    .block-login-myb2b .connxtion_body {
        border-radius: 5px!important;
    }
    .block-login-myb2b{
        padding-left: 15px;
    }
    .block-login-myb2b .div_text_ou{
        margin-top: 0px !important;
    }
    .is_user_logged_in .myb2b-ou{
        max-width: 100% !important;
    }
}


