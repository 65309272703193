.widget_categories ul li:before, .widget_archive ul li:before, .widget_meta ul li:before, .widget_pages ul li:before, .widget_recent_comments ul li:before, .widget_nav_menu ul li:before, .widget_recent_entries ul li:before, .widget_services ul li:before{
    display: none !important;
}
.icone-footer{
    float: left;
    margin-left: -25px;
}
.footer-bottom{
    overflow: hidden;
}
@media (max-width: 700px) {
    .widget_getintuch li {
        padding-left: 10px;
        margin-left: 20px;
    }
    .b2b-infos-legales ul {
        flex-wrap: wrap;
        justify-content: center;
    }
    .b2b-infos-legales ul li{
        margin-bottom: 0px !important;
    }
    .text-Copyright-SMART span {
        text-align: center;
        font-size: 14px;
    } 
    .copyright-style {
        width: 90px;
    }
    .platformes-b2b ul{
        margin-left: 20px;
    }
}